import { Component, Input } from "@angular/core";
import { Candidate, OnlineApplication } from "@ankaadia/graphql";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { OnlineApplicationComponent } from "../../online-application/online-application-applications/online-application.component";
// import { Subscription } from "rxjs";
// import { OnlineApplicationService } from "../../online-application/online-application.service";

@Component({
  selector: "app-candidate-online-application",
  templateUrl: "./candidate-online-application.component.html",
  imports: [TranslateDirective, OnlineApplicationComponent],
})
export class CandidateOnlineApplicationComponent {
  private candidate: Candidate;
  // private subscription: Subscription;

  @Input()
  collectionOrganizationId: string;

  @Input()
  collectionId: string;

  @Input()
  get model(): Candidate {
    return this.candidate;
  }

  set model(model: Candidate) {
    this.candidate = model;
    // if (this.candidate?.id) {
    //   this.loadOnlineApplications();
    // }
  }

  @Input()
  readonly: boolean;

  templates: OnlineApplication[];

  // constructor(
  //   // private readonly onlineApplicationService: OnlineApplicationService
  //   //private readonly settingsService: SettingsService
  // ) {}

  // loadOnlineApplications(): void {
  //   console.log("loadOnlineApplications, types []");
  //   this.subscription?.unsubscribe();
  //   if (this.model.id != null) {
  //     this.subscription = this.onlineApplicationService
  //       .getAvailableTemplates({
  //         candidateIds: [{ id: this.candidate.id, orgId: this.candidate.organizationId }],
  //         types: [],
  //       })
  //       .subscribe(
  //         (x) =>
  //           (this.templates = x.map((app) => {
  //             return {
  //               token: app.token,
  //               recipeId: app.recipeId,
  //               applicationStatusDate: app.applicationStatusDate,
  //               author: app.appliedByUserId,
  //               recipient: app.recipient,
  //               candidateId: app.candidateId,
  //               organizationId: app.organizationId,
  //               id: app.id, //most likely the result id which can be skipped
  //             };
  //           }))
  //       );
  //   }
  // }

  // ngOnDestroy(): void {
  //   this.subscription?.unsubscribe();
  // }
}
