import { NgIf } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CandidateTabConfiguration } from "@ankaadia/ankaadia-shared";
import { GetUserInboxForCandidateViewQuery } from "@ankaadia/graphql";
import { cloneDeep } from "lodash";
import { TabView, TabViewModule } from "primeng-v17/tabview";
import { BehaviorSubject, filter, Observable, startWith, take, tap } from "rxjs";
import { InViewTriggerComponent } from "../../../shared/in-view-trigger/in-view-trigger.component";
import { TabViewRememberTabDirective } from "../../../shared/primeng/tab-view-remember-tab/tab-view-remember-tab.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { CandidateForm } from "../../candidate-form/candidate-form.model";
import { CandidateProcessStatusComponent } from "../../candidate-form/candidate-process-status/candidate-process-status.component";
import { CandidateTaskInbox } from "../../process/task-inbox/task-inbox.model";
import { TaskInboxService } from "../../process/task-inbox/task-inbox.service";
import { CandidateProcessTasksComponent } from "../candidate-process-tasks/candidate-process-tasks.component";
import { CandidateProcessesComponent } from "../candidate-processes/candidate-processes.component";

@Component({
  selector: "app-candidate-process-overview",
  templateUrl: "./candidate-process-overview.component.html",
  styleUrl: "./candidate-process-overview.component.scss",
  imports: [
    TranslateDirective,
    TabViewModule,
    TabViewRememberTabDirective,
    NgIf,
    CandidateProcessStatusComponent,
    InViewTriggerComponent,
    CandidateProcessesComponent,
    CandidateProcessTasksComponent,
  ],
})
export class CandidateProcessOverviewComponent implements AfterViewInit {
  @Input({ required: true }) form: CandidateForm;
  @Input({ required: true }) readonly: boolean;
  @Input({ required: true }) tabs: CandidateTabConfiguration;

  @Output() readonly taskActed = new EventEmitter<void>();

  @ViewChild(TabView) tabView: TabView;

  disableTaskButtons = false;
  searchedTask: { text: string; processId: string };

  processes: CandidateTaskInbox["processes"] = [];
  tasks: CandidateTaskInbox["tasks"] = [];
  collections: CandidateTaskInbox["collections"] = [];

  processTabsInView$ = new BehaviorSubject<boolean>(false);

  get candidateId(): string {
    return this.form.value.id;
  }

  constructor(
    private readonly inboxService: TaskInboxService,
    private readonly cdr: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef
  ) {}

  ngAfterViewInit(): void {
    this.setupLoadProcessData();
    this.taskActed
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.loadAndSetProcessData())
      )
      .subscribe();
  }

  private setupLoadProcessData(): void {
    // load the process data when one of the using tabs is in view
    this.processTabsInView$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(this.processTabsInView$.value),
        filter((x) => x),
        take(1),
        tap(() => this.loadAndSetProcessData())
      )
      .subscribe();
  }

  searchTask(text: string, processId: string): void {
    this.navigateToTaskTab();
    this.searchedTask = { text, processId };
  }

  loadAndSetProcessData(): void {
    this.getInbox()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x) => this.setData(x));
  }

  private setData(x: GetUserInboxForCandidateViewQuery["getUserInboxForCandidateView"]): void {
    this.processes = cloneDeep(x.processes);
    this.tasks = cloneDeep(x.tasks);
    this.collections = cloneDeep(x.collections);
  }

  private navigateToTaskTab(): void {
    this.tabView.open(null, this.tabView.tabs[2]);
    this.tabView.activeIndex = 2;
    this.tabView.cd.detectChanges();
    this.cdr.detectChanges();
  }

  private getInbox(): Observable<GetUserInboxForCandidateViewQuery["getUserInboxForCandidateView"]> {
    const { id, organizationId } = this.form.value;
    return this.inboxService.getUserInboxForCandidateView(id, organizationId);
  }
}
