import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FieldActivityDiffFragment } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { PrimeTemplate } from "primeng-v17/api";
import { TableModule } from "primeng-v17/table";
import { AppDateTimePipe } from "../../../shared/pipes/date.pipe";
import { TableFrozenColumnFixDirective } from "../../../shared/primeng/table-frozen-column-fix/table-frozen-column-fix.directive";
import { TableOptionsDirective } from "../../../shared/primeng/table-options/table-options.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";

@Component({
  selector: "app-field-diff",
  templateUrl: "./field-diff.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateDirective,
    TableModule,
    TableFrozenColumnFixDirective,
    TableOptionsDirective,
    PrimeTemplate,
    AppDateTimePipe,
  ],
})
export class FieldDiffComponent {
  readonly language = this.transloco.getActiveLang();

  @Input()
  diff: FieldActivityDiffFragment[];

  constructor(private readonly transloco: TranslocoService) {}
}
