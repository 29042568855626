import { NgIf } from "@angular/common";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import {
  Candidate,
  CandidateInterviewsFeedback,
  CommentData,
  EmployerData,
  FeedbackData,
  FeedbackIterationData,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { clone } from "lodash";
import { ConfirmationService, PrimeIcons, PrimeTemplate } from "primeng-v17/api";
import { BlockUIModule } from "primeng-v17/blockui";
import { CardModule } from "primeng-v17/card";
import { SplitterModule } from "primeng-v17/splitter";
import { Subject } from "rxjs";
import { SplitterFixDirective } from "../../../shared/primeng/splitter-fix/splitter-fix.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { MessageService } from "../../message/message.service";
import { CandidateInterviewFeedbacksService } from "./candidate-interviews-feedback.service";
import { InterviewIterationDetailsComponent } from "./interview-iteration-details/interview-iteration-details.component";
import { InterviewIterationSelectorComponent } from "./interview-iteration-selector/interview-iteration-selector.component";

export type InterviewData = EmployerData | FeedbackData | CommentData;

@Component({
  selector: "app-candidate-interviews-feedback",
  templateUrl: "./candidate-interviews-feedback.component.html",
  styleUrl: "./candidate-interviews-feedback.component.scss",
  imports: [
    TranslateDirective,
    NgIf,
    BlockUIModule,
    CardModule,
    SplitterModule,
    SplitterFixDirective,
    PrimeTemplate,
    InterviewIterationSelectorComponent,
    InterviewIterationDetailsComponent,
  ],
})
export class CandidateInterviewsFeedbackComponent implements OnChanges {
  interviewsFeedbackChanged = new Subject<FeedbackIterationData | null>();

  @Input()
  candidate: Candidate;

  @Input()
  readonly: boolean;

  interviews: CandidateInterviewsFeedback;
  selectedIteration: FeedbackIterationData;
  showDetails = false;
  highlightedRowId: string;

  constructor(
    private readonly service: CandidateInterviewFeedbacksService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidate) {
      this.load();
    }
  }

  selectFeedback(feedback?: FeedbackIterationData): void {
    this.showDetails = true;
    this.selectedIteration = clone(feedback);
  }

  deleteIteration(feedbackId: string, event?: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("candidate.interviewsFeedback.confirmDelete"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () =>
        this.service.deleteIteration(this.candidate.id, this.candidate.organizationId, feedbackId).subscribe((x) => {
          this.interviews = x;
          this.messageService.add({ severity: "success", summary: translate("candidate.interviewsFeedback.deleted") });
          this.selectedIteration = null;
          this.showDetails = false;
        }),
    });
  }

  refresh(selectedId?: string): void {
    this.load(selectedId);
  }

  private load(selectedId?: string): void {
    if (this.candidate.id && this.candidate.organizationId) {
      this.highlightedRowId = null;
      this.service.get(this.candidate.id, this.candidate.organizationId).subscribe((x) => {
        this.interviews = x;
        this.selectedIteration = selectedId
          ? this.interviews?.interviewsFeedback?.find((i) => i.id === selectedId)
          : null;
        this.showDetails = selectedId ? true : false;
        this.highlightedRowId = selectedId;
      });
    } else {
      this.interviews = null;
      this.selectedIteration = null;
      this.showDetails = false;
      this.highlightedRowId = null;
    }
  }
}
