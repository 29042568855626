import { NgIf } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { nameofFactory, UserPermission } from "@ankaadia/ankaadia-shared";
import {
  EducationCourseCandidateStatus,
  EducationCourseCandidateWeeklyPerformance,
  EducationCourseCandidateWeeklyPerformanceView,
  EducationCourseForCandidateListToAddFragment,
  EducationCourseForViewFragment,
  EducationCoursePerformanceByCandidate,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { difference, isEmpty, orderBy } from "lodash";
import { PrimeIcons } from "primeng-v17/api";
import { ChipModule } from "primeng-v17/chip";
import { DropdownModule } from "primeng-v17/dropdown";
import { TooltipModule } from "primeng-v17/tooltip";
import { finalize, Observable, of, switchMap } from "rxjs";
import { FormElementMapModule } from "../../../shared/from-element-map/form-element-map.module";
import { MessageDialogService } from "../../../shared/message-dialog/message-dialog.service";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { AppendToBodyDirective } from "../../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { SettingsService } from "../../../shared/services/settings.service";
import { TableComponent } from "../../../shared/table/table.component";
import { PipeDescription, TableColumn, TableOperation } from "../../../shared/table/table.model";
import { TestIdDirective } from "../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { EducationCoursePerformanceService } from "../../education/education-courses/education-course-performances/education-course-performance.service";
import {
  EducationCourseAttendanceIcons,
  EducationCourseCandidateStatusIcons,
  EducationCoursePerformanceStatusIcons,
} from "../../education/education-courses/education-course-performances/education-course-performances.model";
import { MessageService } from "../../message/message.service";
import { CandidatesService } from "../candidates.service";
import { CurrentCollectionService } from "../current-collection.service";
import { CandidateEducationCoursePerformanceDialogComponent } from "./candidate-education-course-performance-dialog/candidate-education-course-performance-dialog.component";

const nameOfWeekly = nameofFactory<EducationCourseCandidateWeeklyPerformance & { calendarWeek: string }>();

@Component({
  selector: "app-candidate-education-course-performance",
  templateUrl: "./candidate-education-course-performance.component.html",
  styleUrl: "./candidate-education-course-performance.component.scss",
  imports: [
    TranslateDirective,
    TableComponent,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    NgIf,
    ChipModule,
    TooltipModule,
    CandidateEducationCoursePerformanceDialogComponent,
    EnumPipe,
  ],
})
export class CandidateEducationCoursePerformanceComponent implements OnChanges {
  private readonly today = new Date();
  protected readonly EducationCourseCandidateStatusIcons = EducationCourseCandidateStatusIcons;

  @Input({ required: true }) candidateId: { candidateId: string; candidateOrganizationId: string };
  @Input({ required: true }) courses: EducationCourseForViewFragment[] = [];
  @Input({ required: true }) isReadOnly: boolean;
  @Output() readonly addedToCourse = new EventEmitter<EducationCourseForCandidateListToAddFragment>();

  private readonly hasFullAccess = this.settings.hasAnyPermission([
    UserPermission.Administrator,
    UserPermission.CourseAdministrator,
  ]);

  protected selectedCourse = new FormControl<EducationCourseForViewFragment>(undefined);

  protected candidateState: EducationCourseCandidateStatus;

  protected weeklyPerformance: (EducationCourseCandidateWeeklyPerformanceView & { calendarWeek: string })[] = [];

  protected columns: TableColumn[] = [
    {
      header: translate("week.title"),
      fieldname: nameOfWeekly("calendarWeek"),
      includeInGlobalFilter: true,
    },
    {
      header: translate("attendance.title"),
      fieldname: nameOfWeekly("attendance"),
      pipeDescription: new PipeDescription(EnumPipe, "EducationCourseAttendance"),
      icon: (week: EducationCourseCandidateWeeklyPerformance): string =>
        EducationCourseAttendanceIcons[week.attendance],
      forceDisplayField: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("performance.title"),
      fieldname: nameOfWeekly("performance"),
      includeInGlobalFilter: true,
      icon: (week: EducationCourseCandidateWeeklyPerformance): string =>
        EducationCoursePerformanceStatusIcons[week.performance],
      pipeDescription: new PipeDescription(EnumPipe, "EducationCoursePerformanceStatus"),
      forceDisplayField: true,
    },
    {
      header: translate("comment.title"),
      fieldname: nameOfWeekly("comment"),
      includeInGlobalFilter: true,
    },
  ];

  protected newOperations = this.getCourseTableOperations();
  protected showSidebar = false;
  protected saveDisabled = false;

  constructor(
    private readonly coursePerformanceService: EducationCoursePerformanceService,
    private readonly settings: SettingsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly candidateService: CandidatesService,
    private readonly currentCollectionService: CurrentCollectionService,
    private readonly messageService: MessageService,
    private readonly errorService: MessageDialogService,
    private readonly destroyRef: DestroyRef
  ) {
    this.selectedCourse.valueChanges
      .pipe(
        takeUntilDestroyed(),
        switchMap((course) => this.getCourse(course))
      )
      .subscribe((coursePerformance) => {
        this.candidateState = coursePerformance?.candidateState;
        this.weeklyPerformance = coursePerformance.weeks.map((week, index) => ({
          ...week,
          calendarWeek: translate("week.index", { index: index + 1, number: week.week.number }),
        }));
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.courses) {
      const currentCourses = (this.courses ?? []).filter((x) => x.endDate >= this.today);
      const pastCourses = difference(this.courses ?? [], currentCourses);

      const orderedCourses = [...orderBy(currentCourses, "endDate"), ...orderBy(pastCourses, "endDate", "desc")];

      if (!isEmpty(orderedCourses)) this.selectedCourse.setValue(orderedCourses[0]);
    }
  }

  getCourse(course: EducationCourseForViewFragment): Observable<EducationCoursePerformanceByCandidate | null> {
    if (!course || !this.candidateId) return of(null);
    return this.coursePerformanceService.listForCandidate({
      ...this.candidateId,
      courseId: course.id,
      organizationId: course.organizationId,
    });
  }

  addToCourse(course: EducationCourseForCandidateListToAddFragment): void {
    this.saveDisabled = true;
    this.candidateService
      .addCandidateToCollections(
        this.candidateId.candidateId,
        this.candidateId.candidateOrganizationId,
        this.currentCollectionService.collectionId,
        this.currentCollectionService.organizationId,
        [{ collectionId: course?.participantCollectionId, organizationId: course?.organizationId }]
      )
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.saveDisabled = false;
          this.showSidebar = false;
        })
      )
      .subscribe((result) => {
        if (!result.error) {
          this.messageService.add({
            severity: "success",
            summary: translate("candidate.assigned"),
          });
        } else {
          this.errorService.showMessage(
            translate("candidate.assignFailed"),
            translate("educationCourses.addToCourse.error")
          );
        }

        this.addedToCourse.emit(course);
      });

    if (!this) this.changeDetectorRef.detectChanges();
  }

  private getCourseTableOperations(): TableOperation[] {
    return [
      {
        label: translate("educationCourses.addToCourse.title"),
        icon: PrimeIcons.PLUS,
        operation: (): void => {
          this.showSidebar = true;
        },
        disabled: (): boolean => this.isReadOnly || !this.hasFullAccess,
      },
    ];
  }
}
