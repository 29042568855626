import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Candidate } from "@ankaadia/graphql";
import { ImagePreloadDirective } from "../../../shared/directives/image-preload.directive";
import { ThumbnailPipe } from "../../../shared/pipes/thumbnail.pipe";

@Component({
  selector: "app-candidate-image",
  templateUrl: "./candidate-image.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ImagePreloadDirective, AsyncPipe, ThumbnailPipe],
})
export class CandidateImageComponent {
  @Input()
  candidate: Candidate;
}
