import { Component, Input, OnDestroy } from "@angular/core";
import { Candidate } from "@ankaadia/graphql";
import { BlockUIModule } from "primeng-v17/blockui";
import { combineLatest, Subscription } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { DocumentAutogenService } from "../../document-autogen/document-autogen.service";
import {
  DocumentTemplateForList,
  DocumentTemplatesComponent,
} from "../../document-template/document-template-templates/document-templates.component";
import { DocumentTemplatesService } from "../../document-template/document-templates.service";

@Component({
  selector: "app-candidate-document-templates",
  templateUrl: "./candidate-document-templates.component.html",
  imports: [TranslateDirective, BlockUIModule, DocumentTemplatesComponent],
})
export class CandidateDocumentTemplatesComponent implements OnDestroy {
  private candidate: Candidate;
  private subscription: Subscription;

  @Input()
  collectionOrganizationId: string;

  @Input()
  collectionId: string;

  @Input()
  get model(): Candidate {
    return this.candidate;
  }

  set model(model: Candidate) {
    this.candidate = model;
    if (this.candidate?.id) {
      this.loadTemplates();
    }
  }

  @Input()
  readonly: boolean;

  templates: DocumentTemplateForList[];

  constructor(
    private readonly templateService: DocumentTemplatesService,
    private readonly documentAutogenService: DocumentAutogenService,
    private readonly settings: SettingsService
  ) {}

  loadTemplates(): void {
    this.subscription?.unsubscribe();
    if (this.model.id != null) {
      this.subscription = combineLatest([
        this.templateService.getAllForCandidate(this.model.id, this.model.organizationId, this.settings.organizationId),
        this.documentAutogenService.get(this.settings.organizationId),
      ]).subscribe(
        ([templates, alwaysGenerated]) =>
          (this.templates = templates.map((template) => ({
            ...template,
            alwaysGenerated: alwaysGenerated.some((x) => x.name === template.name && x.type === template.type),
          })))
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
