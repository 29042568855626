import { NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, Input, Output } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import { UserPermission } from "@ankaadia/ankaadia-shared";
import { EducationModuleForSelectionFragment } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng-v17/api";
import { SidebarModule } from "primeng-v17/sidebar";
import { tap } from "rxjs";
import { SidebarAdditionalComponentDirective } from "../../../shared/primeng/sidebar-additional-component/sidebar-additional-component.directive";
import { SidebarOptionsDirective } from "../../../shared/primeng/sidebar-options/sidebar-options.directive";
import { SettingsService } from "../../../shared/services/settings.service";
import { TableOperation } from "../../../shared/table/table.model";
import { EducationExamParticipantsService } from "../../education/education-exam-participants/education-exam-participants.service";
import {
  EducationExamTableComponent,
  ExamFragment,
} from "../../education/education-exams/education-exam-table/education-exam-table.component";
import { MessageService } from "../../message/message.service";
import { CandidateEducationExamsAddToExamDialogComponent } from "./candidate-education-exams-add-to-exam-dialog/candidate-education-exams-add-to-exam-dialog.component";

export type CandidateExamFragment = ExamFragment & { participantId: string };

@Component({
  selector: "app-candidate-education-exams",
  templateUrl: "./candidate-education-exams.component.html",
  imports: [
    EducationExamTableComponent,
    SidebarModule,
    SidebarAdditionalComponentDirective,
    SidebarOptionsDirective,
    NgIf,
    CandidateEducationExamsAddToExamDialogComponent,
  ],
})
export class CandidateEducationExamsComponent {
  @Input({ required: true }) isReadOnly: boolean;
  @Input({ required: true }) exams: CandidateExamFragment[];
  @Input({ required: true }) modules: EducationModuleForSelectionFragment[];
  @Input({ required: true })
  candidateData: {
    candidateId: string;
    candidateOrganizationId: string;
  };

  @Output() readonly removed = new EventEmitter<ExamFragment>();
  @Output() readonly updated = new EventEmitter<ExamFragment>();
  @Output() readonly created = new EventEmitter<CandidateExamFragment>();

  protected examTableOperations = this.getExamTableOperations();

  protected newExamTableOperations = this.getNewExamTableOperations();

  protected showSidebar = false;

  private readonly hasFullAccess = this.settings.hasAnyPermission([
    UserPermission.Administrator,
    UserPermission.CourseAdministrator,
  ]);

  constructor(
    private readonly educationExamParticipantsService: EducationExamParticipantsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly destroyRef: DestroyRef,
    private readonly settings: SettingsService
  ) {}

  addCandidateToExam(exam: ExamFragment): void {
    this.educationExamParticipantsService
      .createExamParticipant({
        examId: exam.id,
        candidateId: this.candidateData.candidateId,
        candidateOrganizationId: this.candidateData.candidateOrganizationId,
        organizationId: exam.organizationId,
      })
      .subscribe((x) => {
        this.created.emit({ ...exam, participantId: x.id });
        this.showSidebar = false;
      });
  }

  closeSideBar(): void {
    this.showSidebar = false;
    this.changeDetectorRef.detectChanges();
  }

  private deleteOperation(exam: CandidateExamFragment, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("educationExamParticipants.confirmDelete"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.educationExamParticipantsService
          .deleteExamParticipant(
            {
              candidateId: this.candidateData.candidateId,
              candidateOrganizationId: this.candidateData.candidateOrganizationId,
              examId: exam.id,
              organizationId: exam.organizationId,
            },
            this.candidateData.candidateId
          )
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            tap(() =>
              this.messageService.add({
                detail: translate("educationExamParticipants.deleted.message"),
                severity: "success",
                summary: translate("educationExamParticipants.deleted.title"),
              })
            )
          )
          .subscribe(() => this.removed.emit(exam));
      },
    });
  }

  private manageParticipants(exam: ExamFragment): void {
    void this.router.navigate([`app/education/exam-participants/${exam.organizationId}/${exam.id}`], {
      relativeTo: this.route.root,
    });
  }

  private getExamTableOperations(): TableOperation[] {
    return [
      {
        label: translate("educationExams.showParticipants"),
        icon: PrimeIcons.USER_EDIT,
        operation: (exam: ExamFragment): void => this.manageParticipants(exam),
        disabled: (exam: CandidateExamFragment): boolean =>
          this.isReadOnly || !this.hasFullAccess || exam.organizationId != this.settings.organizationId,
      },
      {
        label: translate("common.delete"),
        icon: PrimeIcons.TRASH,
        operation: (exam: any, e: Event): void => this.deleteOperation(exam, e),
        disabled: (exam: CandidateExamFragment): boolean =>
          this.isReadOnly || !exam.participantId || !this.hasFullAccess,
      },
    ];
  }

  private getNewExamTableOperations(): TableOperation[] {
    return [
      {
        label: translate("educationExams.addToExam.title"),
        icon: PrimeIcons.PLUS,
        operation: (): void => {
          this.showSidebar = true;
        },
        disabled: (): boolean => this.isReadOnly || !this.hasFullAccess,
      },
    ];
  }
}
