import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { EducationExamResultFullDataFragment } from "@ankaadia/graphql";
import { PrimeTemplate } from "primeng-v17/api";
import { CardModule } from "primeng-v17/card";
import { DropdownModule } from "primeng-v17/dropdown";
import { EditToolBarComponent } from "../../../../shared/edit-tool-bar/edit-tool-bar.component";
import { AppendToBodyDirective } from "../../../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { TranslateDirective } from "../../../../shared/transloco/translate.directive";
import { ExamFragment } from "../../../education/education-exams/education-exam-table/education-exam-table.component";

@Component({
  selector: "app-candidate-education-exam-results-dialog",
  templateUrl: "./candidate-education-exam-results-dialog.component.html",
  imports: [
    TranslateDirective,
    CardModule,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormsModule,
    PrimeTemplate,
    EditToolBarComponent,
  ],
})
export class CandidateEducationExamResultsDialogComponent implements OnChanges {
  @Input({ required: true }) exams: ExamFragment[];
  @Input({ required: true }) examResults: Partial<EducationExamResultFullDataFragment>[];

  @Output()
  readonly saved = new EventEmitter<ExamFragment>();

  @Output()
  readonly closed = new EventEmitter<void>();

  protected exam: ExamFragment;
  protected options: ExamFragment[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exams || changes.examResults) {
      this.options = this.exams.filter((exam) => !this.examResults.some((result) => result.examId === exam.id));
    }
  }

  save(): void {
    this.saved.emit(this.exam);
  }

  cancel(): void {
    this.closed.emit();
  }
}
