import { AsyncPipe, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InstanceStateEnum, Process } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { isEmpty, isNil } from "lodash";
import { MultiSelectModule } from "primeng-v17/multiselect";
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, of, startWith, switchMap } from "rxjs";
import { FormElementMapModule } from "../../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../../shared/primeng/append-to-body/append-to-body.directive";
import { MultiSelectEditableColumnAutoFocusDirective } from "../../../shared/primeng/multi-select-editable-column-auto-focus/multi-select-editable-column-auto-focus.directive";
import { MultiSelectReadonlyFixDirective } from "../../../shared/primeng/multi-select-readonly-fix/multi-select-readonly-fix.directive";
import { MultiSelectSelectedItemsLabelDirective } from "../../../shared/primeng/multi-select-selectedItemsLabel/multi-select-selectedItemsLabel.directive";
import { MultiSelectSortSelectedValuesOnTopDirective } from "../../../shared/primeng/multi-select-sort-selected-values-on-top/multi-select-empty.directive";
import { valuesOf } from "../../../shared/services/form.helper";
import { TestIdDirective } from "../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { CandidateForm } from "../../candidate-form/candidate-form.model";
import { ProcessTasksComponent } from "../../process/process-tasks/process-tasks.component";
import { CandidateForList } from "../../process/process-tasks/process-tasks.model";
import { ProcessService } from "../../process/process.service";
import { CandidateTaskInbox } from "../../process/task-inbox/task-inbox.model";

@Component({
  selector: "app-candidate-process-tasks",
  templateUrl: "./candidate-process-tasks.component.html",
  styleUrl: "./candidate-process-tasks.component.scss",
  imports: [
    TranslateDirective,
    ProcessTasksComponent,
    MultiSelectModule,
    AppendToBodyDirective,
    MultiSelectEditableColumnAutoFocusDirective,
    MultiSelectReadonlyFixDirective,
    MultiSelectSelectedItemsLabelDirective,
    MultiSelectSortSelectedValuesOnTopDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    AsyncPipe,
    NgIf,
  ],
})
export class CandidateProcessTasksComponent implements OnInit, OnChanges {
  protected readonly selectedProcessesControl = new FormControl<Process[]>([]);
  protected readonly language = this.transloco.getActiveLang();
  protected readonly allowedTaskStates = this.getAllowedStates();
  protected readonly defaultTaskStatus = [InstanceStateEnum.Started, InstanceStateEnum.Active];
  protected readonly tasks$ = new BehaviorSubject<CandidateTaskInbox["tasks"]>([]);
  protected readonly selectedTaskStatus$ = new BehaviorSubject<InstanceStateEnum[]>(this.defaultTaskStatus);
  protected readonly shownTasks$ = this.getShownTasks();

  protected candidate$: Observable<CandidateForList>;
  protected process$: Observable<Process>;

  @Input({ required: true }) form: CandidateForm;
  @Input({ required: true }) processes: CandidateTaskInbox["processes"];
  @Input({ required: true }) tasks: CandidateTaskInbox["tasks"];
  @Input({ required: true }) collections: CandidateTaskInbox["collections"];
  @Input({ required: true }) readonly: boolean;
  @Input({ required: true }) buttonsDisabled: boolean;
  @Input() searchedTask: { text: string; processId: string };

  @Output() readonly taskActed = new EventEmitter<void>();
  @Output() readonly taskConfigured = new EventEmitter<void>();
  @Output() readonly taskDeleted = new EventEmitter<void>();

  @ViewChild(ProcessTasksComponent) taskListComponent: ProcessTasksComponent;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly processService: ProcessService
  ) {}

  ngOnInit(): void {
    this.candidate$ = valuesOf(this.form).pipe(
      distinctUntilChanged(
        (previous, current) => previous.id === current.id && previous.organizationId === current.organizationId
      )
    );

    this.process$ = valuesOf(this.form).pipe(
      map((candidate) => candidate.organizationId),
      distinctUntilChanged(),
      switchMap((organizationId) =>
        isNil(organizationId) ? of(null) : this.processService.acquireAdhocProcess({ organizationId })
      )
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchedTask?.currentValue) {
      this.selectProcess(this.searchedTask?.processId);
      this.searchTaskByText(this.searchedTask?.text);
    }

    if (changes.tasks) {
      this.tasks$.next(this.tasks ?? []);
    }
  }

  private searchTaskByText(text: string): void {
    if (!text || !this.taskListComponent) return;
    this.taskListComponent.search(text);
  }

  private selectProcess(processId: string): void {
    const process = this.processes?.find((process) => process.id === processId);
    if (process) this.selectedProcessesControl.setValue([process]);
  }

  private getAllowedStates(): InstanceStateEnum[] {
    return Object.values(InstanceStateEnum);
  }

  private getShownTasks(): Observable<CandidateTaskInbox["tasks"]> {
    const selectedProcesses$ = valuesOf(this.selectedProcessesControl).pipe(
      startWith(this.selectedProcessesControl.value)
    );

    return combineLatest([selectedProcesses$, this.selectedTaskStatus$, this.tasks$]).pipe(
      map(([processes, status, tasks]) =>
        tasks
          .filter((task) => isEmpty(processes) || processes.some((process) => process.id === task.rootId))
          .filter((task) => isEmpty(status) || status.includes(task.instanceState))
      )
    );
  }
}
