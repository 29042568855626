import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Candidate, CandidateStatus, CandidateTagFragment } from "@ankaadia/graphql";
import { TooltipModule } from "primeng-v17/tooltip";
import { CandidateTagColors } from "../../organizations/candidate-tag-selector/candidate-tag-selector.model";
import { CandidateForm } from "../candidate-form.model";
import { CandidateFunctionComponent } from "../candidate-function/candidate-function.component";
import { CandidateProfessionComponent } from "../candidate-profession/candidate-profession.component";
import { CandidateSpecialTagService } from "../candidate-special-tag/candidate-special-tag.service";
import { CandidateStatusComponent } from "../candidate-status/candidate-status.component";

@Component({
  selector: "app-candidate-tags",
  templateUrl: "./candidate-tags.component.html",
  imports: [
    NgIf,
    CandidateStatusComponent,
    CandidateFunctionComponent,
    CandidateProfessionComponent,
    NgFor,
    NgClass,
    TooltipModule,
    AsyncPipe,
  ],
})
export class CandidateTagsComponent {
  readonly CandidateStatus = CandidateStatus;
  readonly CandidateTagColors = CandidateTagColors;
  readonly specialTags$ = this.specialTagService.tags$;

  @Input()
  candidate: Candidate;

  @Input()
  customTags: CandidateTagFragment[];

  @Input()
  form: CandidateForm;

  @Input()
  isEditing: boolean;

  @Input()
  isAdmin: boolean;

  @ViewChild("statusContainer")
  statusContainer: ElementRef;

  @ViewChild("functionContainer")
  functionContainer: ElementRef;

  @ViewChild("professionContainer")
  professionContainer: ElementRef;

  constructor(private readonly specialTagService: CandidateSpecialTagService) {}

  toTestId(tag: CandidateTagFragment): string {
    return `tag-${tag.variable?.replace(".", "-")}`;
  }
}
