import { NgIf } from "@angular/common";
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { UserPermission } from "@ankaadia/ankaadia-shared";
import {
  EducationExamResultFullDataFragment,
  EducationExamResultUpdateResultFragment,
  EducationModuleForSelectionFragment,
  OrganizationForSelectionFragment,
} from "@ankaadia/graphql";
import { Button } from "primeng-v17/button";
import { SidebarModule } from "primeng-v17/sidebar";
import { ToolbarModule } from "primeng-v17/toolbar";
import { Observable } from "rxjs";
import { HasChanges } from "../../../shared/guards/confirm-deactivate.guard";
import { SidebarAdditionalComponentDirective } from "../../../shared/primeng/sidebar-additional-component/sidebar-additional-component.directive";
import { SidebarOptionsDirective } from "../../../shared/primeng/sidebar-options/sidebar-options.directive";
import { ToolbarFixDirective } from "../../../shared/primeng/toolbar-fix/toolbar-fix.directive";
import { SettingsService } from "../../../shared/services/settings.service";
import { TableOperationMode } from "../../../shared/table/table.model";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { EducationExamResultsTableComponent } from "../../education/education-exam-results/education-exam-results-table/education-exam-results-table.component";
import { ExamFragment } from "../../education/education-exams/education-exam-table/education-exam-table.component";
import { CandidateEducationExamResultsDialogComponent } from "./candidate-education-exam-results-dialog/candidate-education-exam-results-dialog.component";

@Component({
  selector: "app-candidate-education-exam-results",
  templateUrl: "./candidate-education-exam-results.component.html",
  imports: [
    TranslateDirective,
    ToolbarModule,
    ToolbarFixDirective,
    Button,
    EducationExamResultsTableComponent,
    SidebarModule,
    SidebarAdditionalComponentDirective,
    SidebarOptionsDirective,
    NgIf,
    CandidateEducationExamResultsDialogComponent,
  ],
})
export class CandidateEducationExamResultsComponent implements HasChanges, OnChanges {
  @Input({ required: true }) modules: EducationModuleForSelectionFragment[];
  @Input({ required: true }) educationProviders: OrganizationForSelectionFragment[];
  @Input({ required: true }) exams: ExamFragment[];
  @Input({ required: true }) examResults: EducationExamResultFullDataFragment[];
  @Input({ required: true }) readonly: boolean;
  @Input({ required: true }) isEditable: boolean;
  @Input({ required: true }) candidateId: { candidateId: string; candidateOrganizationId: string };

  @ViewChild(EducationExamResultsTableComponent)
  private readonly examResultsTable: EducationExamResultsTableComponent;

  protected readonly TableOperationMode = TableOperationMode;
  protected showSidebar = false;

  protected readonly hasFullAccess = this.settings.hasAnyPermission([
    UserPermission.Administrator,
    UserPermission.CourseAdministrator,
  ]);

  constructor(private readonly settings: SettingsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exams || changes.examResults) {
      this.examResults = this.examResults.map((x) => ({
        ...x,
        languageLevel: this.getLanguageLevel(this.exams.find((exam) => exam.id === x.examId)) ?? x.languageLevel,
      }));
    }
  }

  addExamResult($event: ExamFragment): void {
    this.examResults = [...this.examResults, this.createExamResult($event)];
    this.showSidebar = false;
  }

  onExamResultDeleted($event: EducationExamResultFullDataFragment): void {
    this.examResults = this.examResults.filter((x) => x.examId !== $event.examId);
  }

  hasChanges(): boolean {
    return this.examResultsTable?.hasChanges();
  }

  saveAll(): Observable<EducationExamResultUpdateResultFragment[]> {
    return this.examResultsTable.saveAll();
  }

  private createExamResult(exam: ExamFragment): EducationExamResultFullDataFragment {
    return {
      ...this.candidateId,
      examId: exam.id,
      organizationId: exam.organizationId,
      languageLevel: this.getLanguageLevel(exam),
    };
  }

  private getLanguageLevel(exam: ExamFragment | null): string {
    if (!exam) return "";
    if (exam.__typename === "AdHocEducationExam") return exam.languageLevel;
    const module = this.modules?.find((module) => module.id === exam.educationModuleId);
    return module?.languageLevel;
  }
}
