<ng-container *appTranslate="let t">
  <app-online-application
    #table
    class="candidate-files app-form"
    [candidateId]="model?.id"
    [organizationId]="model?.organizationId"
    [collectionId]="collectionId"
    [collectionOrganizationId]="collectionOrganizationId"
    [readonly]="readonly"
    mode="candidate"
  ></app-online-application>
</ng-container>
