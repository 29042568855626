import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FeedbackData, FeedbackIterationData } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { clone } from "lodash";
import { PrimeTemplate } from "primeng-v17/api";
import { ButtonDirective } from "primeng-v17/button";
import { Table, TableModule } from "primeng-v17/table";
import { TooltipModule } from "primeng-v17/tooltip";
import { v4 as uuidv4 } from "uuid";
import { AppDateTimePipe } from "../../../../shared/pipes/date.pipe";
import { TableFrozenColumnFixDirective } from "../../../../shared/primeng/table-frozen-column-fix/table-frozen-column-fix.directive";
import { TableOptionsDirective } from "../../../../shared/primeng/table-options/table-options.directive";
import { TranslateDirective } from "../../../../shared/transloco/translate.directive";

@Component({
  selector: "app-interview-iteration-selector",
  templateUrl: "./interview-iteration-selector.component.html",
  styleUrl: "./interview-iteration-selector.component.scss",
  imports: [
    TranslateDirective,
    TableModule,
    TableFrozenColumnFixDirective,
    TableOptionsDirective,
    PrimeTemplate,
    ButtonDirective,
    TooltipModule,
    AppDateTimePipe,
  ],
})
export class InterviewIterationSelectorComponent implements OnChanges {
  readonly language = this.transloco.getActiveLang();

  @Input()
  candidateId: string;

  @Input()
  iterations: FeedbackIterationData[] = [];

  @Input()
  highlightedRowId?: string;

  @Input()
  readonly: boolean;

  @Output()
  readonly showFeedback = new EventEmitter<FeedbackIterationData>();

  @Output()
  readonly delete = new EventEmitter<{ id: string; event: Event }>();

  @ViewChild(Table)
  table: Table;

  highlighted: any;

  isEditable = false;
  constructor(private readonly transloco: TranslocoService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateId) {
      this.isEditable = false;
    }
    if (changes.iterations?.currentValue) {
      this.iterations = clone(changes.iterations.currentValue);
      this.isEditable = false;
      this.highlighted = null;
    }
    if (changes.highlightedRowId?.currentValue) {
      this.highlightedRowId = changes.highlightedRowId.currentValue;
      this.selectItem({ id: this.highlightedRowId });
    }
  }

  addNewIteration(): void {
    this.isEditable = true;
    const iteration = new FeedbackIterationData();
    iteration.id = uuidv4();
    this.showFeedback.emit(iteration);
    this.table?.value?.push(iteration);
    this.selectItem(iteration);
  }

  selectItem(obj: any): void {
    this.highlighted = this.table?.value?.findIndex((r) => r.id == obj?.id);
  }

  showMaxDate(feedbacks: FeedbackData[]): any {
    return feedbacks?.map((e) => e?.interviewDate).reduce((a, b) => (a > b ? a : b)) ?? null;
  }
}
