<app-default-card-view-component
  [data]="candidateData"
  [sharing]="sharing"
  [files]="candidateFiles"
  [tag]="candidateTag"
  [isPresentation]="isPresentation"
  [selectedCollectionId]="collectionId"
  [selectedOrganizationId]="collectionOrganizationId"
  (filePreview)="filePreview.emit($event)"
></app-default-card-view-component>
