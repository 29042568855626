<ng-container *appTranslate="let t">
  <p-blockUI [target]="panel" [blocked]="!model?.id">
    <span class="block-ui-message">{{ t("candidate.notesWarning") }}</span>
  </p-blockUI>

  <p-panel #panel styleClass="app-form">
    <p-table
      #dt
      [value]="notes"
      [rowHover]="true"
      sortField="changedAt"
      [sortOrder]="-1"
      editMode="row"
      class="app-form"
      styleClass="p-datatable p-datatable-sm app-form"
      dataKey="id"
      [globalFilterFields]="['changedBy', 'contents']"
    >
      <ng-template pTemplate="caption">
        <div class="flex">
          <button
            pButton
            [disabled]="isEditing || readonly"
            type="button"
            icon="pi pi-plus"
            class="p-button-rounded p-button-text"
            (click)="addNote()"
            data-testid="btn-add-note"
          ></button>

          <p-columnFilter type="date" field="changedAt" [showOperator]="false" display="menu"></p-columnFilter>

          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="dt.filterGlobal($any($event.target).value, 'contains')"
              [placeholder]="t('common.search')"
              data-testid="input-search"
            />
          </span>
        </div>
      </ng-template>

      <ng-template pTemplate="body" let-note let-editing="editing" let-index="rowIndex">
        <tr [pEditableRow]="note">
          <td [attr.colspan]="editing ? 3 : 1">
            <p-cellEditor class="w-full">
              <ng-template pTemplate="input">
                <p-editor
                  spellcheck="true"
                  [lang]="language"
                  [readonly]="readonly"
                  (onInit)="updateEditor($event.editor, note)"
                  (onTextChange)="updateHtml($event.htmlValue, 'user', note)"
                  [style]=""
                >
                  <ng-template pTemplate="header">
                    <button
                      *ngIf="editing"
                      [disabled]="readonly"
                      pButton
                      type="button"
                      icon="pi pi-times"
                      class="p-button-rounded p-button-text p-button-danger -ml-2 candiate-notes-apply-cancel-update"
                      (click)="cancelNote($event, note)"
                    ></button>

                    <button
                      *ngIf="editing"
                      [disabled]="!note.contents || readonly"
                      pButton
                      type="button"
                      pSaveEditableRow
                      icon="pi pi-check"
                      class="p-button-rounded p-button-text p-button-success -ml-2 candiate-notes-apply-cancel-update"
                      (click)="saveNote(note)"
                    ></button>

                    <span class="ql-formats">
                      <select class="ql-size"></select>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-bold"></button>
                      <button class="ql-italic"></button>
                      <button class="ql-underline"></button>
                    </span>
                    <span class="ql-formats">
                      <select class="ql-color"></select>
                      <select class="ql-background"></select>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-header" value="1"></button>
                      <button class="ql-header" value="2"></button>
                      <button class="ql-blockquote"></button>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-list" value="ordered"></button>
                      <button class="ql-list" value="bullet"></button>
                      <button class="ql-indent" value="-1"></button>
                      <button class="ql-indent" value="+1"></button>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-direction" value="rtl"></button>
                      <select class="ql-align"></select>
                    </span>

                    <span class="ql-formats">
                      <button class="ql-link"></button>
                    </span>
                  </ng-template>
                </p-editor>
              </ng-template>

              <ng-template pTemplate="output">
                <p-card>
                  <ng-template pTemplate="header">
                    <div class="candiate-notes-card-header">
                      <span class="candiate-notes-changedBy"> {{ note.changedBy }}</span>
                      <span class="candiate-notes-changedAt">
                        {{ note.changedAt | appDateTime: { dateStyle: "short", timeStyle: "short" } : language }}</span
                      >
                      <button
                        *ngIf="!editing"
                        [disabled]="isEditing || readonly"
                        pButton
                        type="button"
                        pInitEditableRow
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button-text -mr-2"
                        (click)="initNote(note)"
                      ></button>
                      <button
                        *ngIf="!editing"
                        [disabled]="isEditing || readonly"
                        pButton
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-text p-button-danger -mr-2"
                        (click)="deleteNote(note, $event)"
                      ></button>
                    </div>
                  </ng-template>

                  <p class="candiate-notes-card-body" [innerHTML]="note.contents | safe: 'html'"></p>
                </p-card>
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-panel>
</ng-container>
