<div class="app-form" *appTranslate="let t">
  <p-table
    #table
    [value]="iterations"
    dataKey="id"
    class="app-form"
    styleClass="p-datatable-with-overflows app-form"
    selectionMode="single"
    (onRowSelect)="showFeedback.emit($event.data); selectItem($event.data)"
    sortField="createdAt"
    [sortOrder]="-1"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>{{ t("candidate.interviewsFeedback.employer") }}</th>
        <th pSortableColumn="createdAt">
          {{ t("date.title") }}
          <p-sortIcon field="createdAt"></p-sortIcon>
        </th>
        <th>
          <button
            [disabled]="isEditable || readonly"
            pButton
            type="button"
            icon="pi pi-plus"
            tabIndex="-1"
            class="toggle p-button-rounded p-button-text"
            (click)="addNewIteration()"
          ></button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-iteration let-idx="rowIndex">
      <tr [pSelectableRow]="iteration" [class.p-highlight]="idx === highlighted">
        <td>
          <div class="white-space-nowrap text-overflow-ellipsis overflow-hidden">{{ iteration.employer?.name }}</div>
        </td>
        <td>
          <div class="white-space-nowrap text-overflow-ellipsis overflow-hidden">
            {{ showMaxDate(iteration?.feedbacks) | appDateTime: { dateStyle: "short", timeStyle: "short" } : language }}
          </div>
        </td>
        <td>
          <button
            pButton
            type="button"
            icon="pi pi-trash"
            class="p-button-rounded p-button-text p-button-danger"
            [pTooltip]="t('messenger.show')"
            tooltipPosition="left"
            [disabled]="!iterations?.length || readonly"
            (click)="delete.emit({ id: iteration?.id, event: $event })"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
