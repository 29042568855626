import { AsyncPipe, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { DropdownModule } from "primeng-v17/dropdown";
import { FormElementMapModule } from "../../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { StaticDataPipe } from "../../../shared/static-data/static-data.pipe";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { TestIdDirective } from "../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";

@Component({
  selector: "app-candidate-function",
  templateUrl: "./candidate-function.component.html",
  imports: [
    TranslateDirective,
    NgIf,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    AsyncPipe,
    StaticDataPipe,
  ],
})
export class CandidateFunctionComponent {
  readonly StaticDataType = StaticDataType;
  readonly functions$ = this.staticDataService.getStaticData(StaticDataType.Functions, this.transloco.getActiveLang());

  @Input()
  control: FormControl;

  @Input()
  readonly: boolean;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService
  ) {}
}
