import { NgIf } from "@angular/common";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { DropdownModule } from "primeng-v17/dropdown";
import { TooltipModule } from "primeng-v17/tooltip";
import { FlagComponent } from "../../../shared/flag/flag.component";
import { FormElementMapModule } from "../../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { TestIdDirective } from "../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";

@Component({
  selector: "app-candidate-immigration-country",
  templateUrl: "./candidate-immigration-country.component.html",
  styleUrl: "./candidate-immigration-country.component.scss",
  imports: [
    TranslateDirective,
    NgIf,
    TooltipModule,
    FlagComponent,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
  ],
})
export class CandidateImmigrationCountryComponent implements OnInit, OnChanges {
  readonly language = this.transloco.getActiveLang();

  @Input()
  country: string;

  @Input()
  control: FormControl;

  @Input()
  candidate: { candidateId: string; organizationId: string };

  @Input()
  readonly = true;

  @Input()
  appearDisabled: boolean;

  protected options = [];
  private supportedOrgCountries = [];
  private allCountries = [];
  private organizationId: string;
  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly settings: SettingsService
  ) {}

  get immigrationCountry(): string {
    return this.country ?? this.settings.standardImmigrationCountry;
  }

  get isNewCandidate(): boolean {
    return (!this.candidate.candidateId && this.options?.length > 1) || !!this.candidate.candidateId;
  }

  ngOnInit(): void {
    this.supportedOrgCountries = this.settings.supportedImmigrationCountries;
    this.staticDataService
      .getStaticData(StaticDataType.SupportedImmigrationCountries, this.language)
      .subscribe((countries) => {
        this.allCountries = countries;
        this.updateOptions();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.candidate) {
      if (changes.candidate?.currentValue?.organizationId !== this.organizationId) {
        this.organizationId = changes.candidate?.currentValue?.organizationId ?? this.settings.organizationId;
      }
    }
    if ((changes.readonly || changes.country) && this.allCountries.length) {
      this.country = changes.country?.currentValue ?? this.country;
      this.updateOptions();
    }
  }

  updateOptions(): void {
    this.options = this.allCountries?.filter((c) => this.supportedOrgCountries.includes(c.value));
    if (this.country && !this.options.find((c) => c.value === this.country)) {
      this.options.push(this.allCountries.find((c) => c.value === this.country));
    }
  }
}
