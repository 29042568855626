import { Property } from "@ankaadia/ankaadia-shared";
import { Candidate, SupportedImmigrationCountry } from "@ankaadia/graphql";

export interface SelectedCandidateForEdit {
  id?: string;
  organizationId: string;
  immigrationCountry?: SupportedImmigrationCountry;
}

export type CandidateProperty = Property<Candidate>;

export const MultiEditCandidatePreFilledFieldsIfEqualAcrossCandidates: CandidateProperty[] = ["function"];
