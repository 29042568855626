import { AsyncPipe, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { StaticDataType } from "@ankaadia/graphql";
import { FormElementMapModule } from "../../../shared/from-element-map/form-element-map.module";
import { StaticDataPipe } from "../../../shared/static-data/static-data.pipe";
import { TestIdDirective } from "../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { ProfessionSelectorComponent } from "../../profession/profession-selector/profession-selector.component";

@Component({
  selector: "app-candidate-profession",
  templateUrl: "./candidate-profession.component.html",
  imports: [
    TranslateDirective,
    NgIf,
    ProfessionSelectorComponent,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    AsyncPipe,
    StaticDataPipe,
  ],
})
export class CandidateProfessionComponent {
  readonly StaticDataType = StaticDataType;
  readonly Validators = Validators;

  @Input()
  control: FormControl;

  @Input()
  readonly: boolean;

  @Input()
  candidateOrganizationId: string;
}
