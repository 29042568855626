<ng-container *appTranslate="let t">
  <p-tabView #tabView class="vertical" styleClass="app-form" rememberTab stateKey="candidate-vertical-tabs">
    <p-tabPanel [header]="t('processStatus.title')" *ngIf="tabs?.processStatus">
      <app-candidate-process-status [form]="form.controls.processStatus" [readonly]="readonly" />
    </p-tabPanel>
    <p-tabPanel [header]="t('processes.view')" *ngIf="tabs?.processOverview">
      @if (candidateId) {
        <app-in-view-trigger (inView)="processTabsInView$.next(true)" />
        <app-candidate-processes
          *ngIf="candidateId"
          [candidateId]="candidateId"
          [processes]="processes"
          (searchTask)="searchTask($event.text, $event.processId)"
        />
      }
    </p-tabPanel>
    <p-tabPanel [header]="t('tasks.title')" *ngIf="tabs?.processTasks">
      @if (candidateId) {
      <app-in-view-trigger (inView)="processTabsInView$.next(true)" />
      <app-candidate-process-tasks
        [form]="form"
        [processes]="processes"
        [tasks]="tasks"
        [collections]="collections"
        [readonly]="readonly"
        [buttonsDisabled]="disableTaskButtons"
        [searchedTask]="searchedTask"
        (taskActed)="taskActed.emit()"
        (taskConfigured)="loadAndSetProcessData()"
        (taskDeleted)="loadAndSetProcessData()"
      />
      }
    </p-tabPanel>
  </p-tabView>
</ng-container>
