import { NgIf } from "@angular/common";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CandidateStatus, StaticDataModel } from "@ankaadia/graphql";
import { DropdownModule } from "primeng-v17/dropdown";
import { FormElementMapModule } from "../../../shared/from-element-map/form-element-map.module";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { AppendToBodyDirective } from "../../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { TestIdDirective } from "../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { getSortedCandidateStatusList } from "./candidate-status-enum-helper";

@Component({
  selector: "app-candidate-status",
  templateUrl: "./candidate-status.component.html",
  imports: [
    TranslateDirective,
    NgIf,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    EnumPipe,
  ],
})
export class CandidateStatusComponent implements OnChanges, OnInit {
  constructor(private readonly staticDataService: StaticDataService) {}

  @Input()
  control: FormControl;

  @Input()
  readonly: boolean;

  @Input()
  canDropOut: boolean;

  options: StaticDataModel[];
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.canDropOut) {
      this.updateOptions(changes.canDropOut.currentValue);
    }
  }

  ngOnInit(): void {
    this.updateOptions(this.canDropOut);
  }

  private updateOptions(canDropOut: boolean): void {
    this.options = getSortedCandidateStatusList(this.staticDataService).filter(
      (x) => canDropOut || x.value !== CandidateStatus.DroppedOut
    );
  }
}
