import { AsyncPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Process, ProcessProgressFragment, ProcessSelectionListFragment } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { first } from "lodash";
import { DropdownModule } from "primeng-v17/dropdown";
import { ToolbarModule } from "primeng-v17/toolbar";
import { filter, map, Observable, switchMap } from "rxjs";
import { FormElementMapModule } from "../../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { ToolbarFixDirective } from "../../../shared/primeng/toolbar-fix/toolbar-fix.directive";
import { valuesOf } from "../../../shared/services/form.helper";
import { TestIdDirective } from "../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { ProcessProgressComponent } from "../../process/process-progress/process-progress.component";
import { ProcessService } from "../../process/process.service";
import { CandidateTaskInbox } from "../../process/task-inbox/task-inbox.model";

@Component({
  selector: "app-candidate-processes",
  templateUrl: "./candidate-processes.component.html",
  styleUrl: "./candidate-processes.component.scss",
  imports: [
    TranslateDirective,
    ToolbarModule,
    ToolbarFixDirective,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    ProcessProgressComponent,
    AsyncPipe,
  ],
})
export class CandidateProcessesComponent implements OnChanges {
  @Input({ required: true }) candidateId: string;
  @Input({ required: true }) processes: CandidateTaskInbox["processes"];

  @Output() readonly searchTask = new EventEmitter<{ text: string; processId: string }>();

  selectedProcessControl = new FormControl<Process>(undefined);
  selectedProcessRoles$ = valuesOf(this.selectedProcessControl).pipe(map((x) => x?.roles));

  language: string = this.transloco.getActiveLang();
  progress$ = valuesOf(this.selectedProcessControl).pipe(
    filter((process) => !!process),
    switchMap((process) => this.getProgress(process))
  );

  constructor(
    private readonly transloco: TranslocoService,
    private readonly processService: ProcessService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.processes && this.processes) {
      this.selectedProcessControl.setValue(first(this.processes));
    }
  }

  onSearch(event: string): void {
    this.searchTask.emit({ text: event, processId: this.selectedProcessControl.value.id });
  }

  private getProgress(process: ProcessSelectionListFragment): Observable<ProcessProgressFragment> {
    return this.processService.getProcessProgress(process.organizationId, process.id, this.candidateId, this.language);
  }
}
