<ng-container *appTranslate="let t">
  <app-document-dropzone
    *ngIf="candidate"
    poolId="single-candidate"
    [candidateOrCollection]="candidate"
    context="DefaultContext"
  >
    <p-card [style]="{ 'overflow-y': isMasterUser && (showFormErrors || showDirtyControls) ? 'auto' : 'inherit' }">
      <form *ngIf="form" class="flex flex-column app-form" [formGroup]="form">
        <div class="icon-group-end">
          <ng-container *ngIf="isMasterUser">
            <p-button
              icon="pi pi-list-check"
              styleClass="p-button-rounded p-button-text mr-2 {{ showFormErrors ? 'p-button-active' : '' }}"
              (onClick)="showFormErrors = !showFormErrors"
              pTooltip="Debug: Show form validation errors"
              tooltipPosition="left"
            />
            <p-button
              icon="pi pi-lightbulb"
              styleClass="p-button-rounded p-button-text mr-2 {{ showDirtyControls ? 'p-button-active' : '' }}"
              (onClick)="showDirtyControls = !showDirtyControls"
              pTooltip="Debug: Show dirty controls"
              tooltipPosition="left"
            />
          </ng-container>

          <p-button
            icon="pi pi-history"
            styleClass="p-button-rounded p-button-text mr-2 {{ fieldDiffService.isOn ? 'p-button-active' : '' }}"
            [disabled]="!isCreator || (!fieldDiffService.canBeActivated && !fieldDiffService.isOn)"
            (onClick)="fieldDiffService.toggle($event)"
            [pTooltip]="t('differences.perField')"
            tooltipPosition="left"
          ></p-button>

          <app-activities-panel *ngIf="candidate.displayId" [source]="activitiesSource"></app-activities-panel>
          <app-candidate-field-diff *ngIf="candidate.displayId"></app-candidate-field-diff>
        </div>

        <p-toolbar>
          <ng-template pTemplate="left">
            <div class="p-avatar p-component border-circle overflow-hidden p-avatar-xl flex-shrink-0">
              <app-candidate-image [candidate]="candidate"></app-candidate-image>
            </div>

            <div class="ml-2 mw-1">
              <div class="delete-request-chip" *ngIf="deletionRequest">
                <span class="delete-request-label">{{ t("candidateDeletion.label") }} </span>
                <span
                  class="pi pi-times-circle delete-request-icon"
                  (click)="removeDeletionRequest($event)"
                  [class.disabled]="!isEditing || isReadOnlyCollaboration"
                ></span>
              </div>
              <div class="candidate-info">
                <app-flag class="mr-2" *ngIf="candidate.displayId" [country]="candidate.country"></app-flag>
                <h3 *ngIf="isMultiEditMode" class="mr-2 mt-1 mb-1">
                  {{ t("candidateMultiEdit.allSelectedCandidates") }}
                </h3>
                <h3 *ngIf="candidate.displayId" class="mr-2 mt-1 mb-1" [class.red]="deletionRequest">
                  {{ candidate.displayId }}-{{ candidate.displayName }}
                </h3>
                <div [ngSwitch]="candidate.os?.profile?.salutation" class="salutation">
                  <div *ngSwitchCase="'MR'" class="gender gender-male">♂</div>
                  <div *ngSwitchCase="'MRS'" class="gender gender-female">♀</div>
                  <div *ngSwitchCase="'MX'" class="gender gender-diverse">⚧</div>
                </div>
                <app-favorite
                  *ngIf="candidate.displayId"
                  [(favorite)]="favorite"
                  [candidate]="{ candidateId: candidate.id, organizationId: candidate.organizationId }"
                ></app-favorite>
                <app-candidate-immigration-country
                  style="display: contents"
                  *ngIf="!isEditing"
                  [control]="form.controls.immigrationCountry"
                  [country]="candidate?.immigrationCountry"
                  [candidate]="{ candidateId: candidate.id, organizationId: candidate.organizationId }"
                ></app-candidate-immigration-country>
                <ng-container *ngIf="!isEditing && targetDisplayId">
                  <div
                    class="p-tag p-component text-base app-tag-danger white-space-nowrap text-overflow-ellipsis overflow-hidden ml-3"
                  >
                    <span class="p-tag-value">Kopie von {{ targetDisplayId }}</span>
                  </div>
                </ng-container>
              </div>

              <div class="flex mw-1">
                <app-candidate-tags
                  class="mw-1"
                  [candidate]="candidate"
                  [customTags]="customTags"
                  [form]="form"
                  [isEditing]="isEditing"
                  [isAdmin]="isAdmin"
                  data-testid="candidate-tags"
                ></app-candidate-tags>

                <div #immigrationCountryContainer>
                  <app-candidate-immigration-country
                    class="mw-1"
                    *ngIf="isEditing"
                    [control]="form.controls.immigrationCountry"
                    [readonly]="false"
                    [appearDisabled]="isMultiEditMode"
                    [country]="candidate?.immigrationCountry"
                    [candidate]="{ candidateId: candidate.id, organizationId: candidate.organizationId }"
                  ></app-candidate-immigration-country>
                </div>

                <div class="mw-1 item-chip" *ngIf="!isEditing && messagesReception?.enabled === false">
                  <i class="pi pi-exclamation-circle"></i>
                  <span class="ml-1">{{ t("candidate.notificationsdisabled") }} </span>
                </div>
              </div>

              <app-candidate-special-tag
                [customTags]="customTags"
                variable="candidate.headerComment"
                [isEditing]="isEditing"
              ></app-candidate-special-tag>
            </div>
          </ng-template>

          <ng-template pTemplate="right">
            <ng-content select="[toolbar]"></ng-content>
          </ng-template>
        </p-toolbar>

        <p-tabView
          *ngIf="tabs"
          class="p-fluid app-form"
          styleClass="flex flex-column app-form"
          [scrollable]="true"
          (onChange)="tabChanges($event)"
          rememberTab
          stateKey="candidate-horizontal-tab"
        >
          <p-tabPanel
            *ngIf="
              tabs.customFields ||
              tabs.general ||
              tabs.qualifications ||
              tabs.furtherEducations ||
              tabs.experiences ||
              tabs.languageSkills ||
              tabs.preferences ||
              tabs.vaccinations ||
              tabs.other ||
              tabs.insuranceAndTax ||
              tabs.bankDetails ||
              tabs.interviews
            "
            [header]="t('candidate.profile')"
            [headerStyleClass]="form.controls.os.controls.profile.invalid ? 'p-error' : ''"
          >
            <app-candidate-profile
              [form]="form.controls.os.controls.profile"
              [readonly]="!isEditing"
              [candidate]="candidate"
              [tabs]="tabs"
              [tabConfiguration]="tabConfiguration"
              [profession]="profession"
              [immigrationCountry]="immigrationCountry"
              [isMultiEditMode]="isMultiEditMode"
              [enabledFields]="enabledFields"
              [isLoading]="isPreparingCandidate"
            ></app-candidate-profile>
          </p-tabPanel>

          <p-tabPanel
            *ngIf="showMigrationTab"
            [header]="t('migration.title')"
            [headerStyleClass]="form.controls.migration.invalid ? 'p-error' : ''"
          >
            <ng-container [ngSwitch]="candidate?.immigrationCountry">
              <app-candidate-migration-at
                *ngSwitchCase="'AT'"
                [form]="form.controls.migration"
                [readonly]="!isEditing || isReadOnlyCollaboration"
                [candidateId]="candidate.id"
                [candidateOrganizationId]="candidate.organizationId"
                [recognitionPath]="candidate.migration?.at?.recognitionPath"
                [employmentRelationship]="candidate.migration?.at?.employmentRelationship"
                [housingAcquisition]="candidate.migration?.housingAcquisition"
                [residence]="candidate.migration?.at?.residence"
                [candidateFunction]="form.controls.function?.value"
                [candidateProfession]="form.controls.profession?.value"
                [tabs]="tabs"
                [isMultiEditMode]="isMultiEditMode"
              ></app-candidate-migration-at>

              <app-candidate-migration-de
                *ngSwitchDefault
                [form]="form.controls.migration"
                [readonly]="!isEditing || isReadOnlyCollaboration"
                [candidateId]="candidate.id"
                [candidateOrganizationId]="candidate.organizationId"
                [candidateProfession]="form.controls.profession?.value"
                [currentRecognitionPath]="candidate.migration?.de?.recognitionPath?.current"
                [alternativeRecognitionPath]="candidate.migration?.de?.recognitionPath?.alternative"
                [employmentRelationship]="candidate.migration?.de?.employmentRelationship"
                [candidateFunction]="form.controls.function?.value"
                [qualificationEvaluation]="candidate.migration?.de?.qualificationEvaluation"
                [housingAcquisition]="candidate.migration?.housingAcquisition"
                [residence]="candidate.migration?.de?.residence"
                [qualificationMeasureDataDe]="candidate.migration?.de?.qualificationMeasure"
                [tabs]="tabs"
                [isMultiEditMode]="isMultiEditMode"
                [candidateMultiEditProperties]="candidateMultiEditProperties"
              ></app-candidate-migration-de>
            </ng-container>
          </p-tabPanel>

          <p-tabPanel *ngIf="tabs.education" [header]="t('education.title')" [cache]="false">
            <ng-template pTemplate="content">
              <app-candidate-education
                [candidateId]="{
                  candidateId: candidate.id,
                  candidateOrganizationId: candidate.organizationId,
                }"
                [readonly]="!isEditing || isReadOnlyCollaboration"
              ></app-candidate-education>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel
            *ngIf="tabs.processStatus || tabs.processOverview || tabs.processTasks"
            [header]="t('process.title')"
          >
            <app-candidate-process-overview
              [form]="form"
              [readonly]="!isEditing || isReadOnlyCollaboration"
              [tabs]="tabs"
              (taskActed)="taskActed.emit()"
            ></app-candidate-process-overview>
          </p-tabPanel>

          <p-tabPanel
            *ngIf="tabs.internal || enableLicenseOwnerSpecificData"
            [header]="t('candidate.internal')"
            [headerStyleClass]="form.controls.internal.invalid ? 'p-error' : ''"
          >
            <app-candidate-license-owner-specific-data
              [isOriginalTabShared]="tabs.internal"
              [isLicenseOwnerSpecificDataEnabled]="enableLicenseOwnerSpecificData"
              [(dataSource)]="candidateInternalTabDataSource"
              class="tab-configuration"
            ></app-candidate-license-owner-specific-data>

            <app-candidate-internal
              *ngIf="tabs.internal"
              [class]="candidateInternalTabDataSource !== 'owner' ? 'hidden' : ''"
              [form]="form.controls.internal"
              [internalData]="candidate.internal"
              [isOwnerData]="true"
              [candidateForm]="form"
              [readonly]="!isEditing"
              [isMultiEditMode]="isMultiEditMode"
              [candidateOrganizationId]="candidate.organizationId"
              [candidateId]="candidate.id"
              [sharingId]="sharing?.id"
              [collectionId]="sharing?.sharedCollectionId"
              [candidateDisplayName]="candidate.displayName"
              [candidateDisplayId]="candidate.displayId"
              (tabChanged)="synchronizeInternalTabs($event)"
            ></app-candidate-internal>

            <app-candidate-internal
              [class]="candidateInternalTabDataSource === 'owner' ? 'hidden' : ''"
              [form]="form.controls.licensedOrganizationData.controls.internal"
              [internalData]="candidate.licensedOrganizationData?.internal"
              [isOwnerData]="false"
              [candidateForm]="form"
              [readonly]="!isEditing"
              [isMultiEditMode]="isMultiEditMode"
              [candidateOrganizationId]="candidate.organizationId"
              [candidateId]="candidate.id"
              [sharingId]="sharing?.id"
              [collectionId]="sharing?.sharedCollectionId"
              [candidateDisplayName]="candidate.displayName"
              [candidateDisplayId]="candidate.displayId"
              (tabChanged)="synchronizeInternalTabs($event)"
            ></app-candidate-internal>
          </p-tabPanel>

          <p-tabPanel *ngIf="tabs.events" [header]="t('candidates.events')" [cache]="false">
            <ng-template pTemplate="content">
              <app-candidate-events-table
                [organizationId]="candidate.organizationId"
                [entityId]="candidate"
              ></app-candidate-events-table>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel *ngIf="tabs.notes" [header]="t('candidate.notes')" [cache]="false">
            <ng-template pTemplate="content">
              <app-candidate-notes [model]="candidate" [readonly]="isReadOnlyCollaboration"></app-candidate-notes>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel *ngIf="tabs.family" [header]="t('family.title')" [cache]="false">
            <app-candidate-family [form]="form.controls.family" [readonly]="!isEditing"></app-candidate-family>
          </p-tabPanel>

          <p-tabPanel *ngIf="isLicensed && tabs.collections" [header]="t('collections.title')" [cache]="false">
            <ng-template pTemplate="content">
              <app-candidate-collections
                [model]="candidate"
                [readonly]="!isEditing || isReadOnlyCollaboration || isUneditableCollaboration"
              ></app-candidate-collections>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel *ngIf="tabs.presentation" [header]="t('candidate.presentation.title')" [cache]="false">
            <app-candidate-presentation
              [form]="form.controls.presentation"
              [readonly]="!isEditing || isReadOnlyCollaboration"
              [candidateId]="candidate.id"
              [candidateOrganizationId]="candidate.organizationId"
              [sharing]="sharing"
            ></app-candidate-presentation>
          </p-tabPanel>

          <p-tabPanel *ngIf="tabs.interviews" [header]="t('candidate.interviewsFeedback.title')" [cache]="false">
            <app-candidate-interviews-feedback
              [candidate]="candidate"
              [readonly]="!isEditing || isReadOnlyCollaboration"
            ></app-candidate-interviews-feedback>
          </p-tabPanel>

          <p-tabPanel *ngIf="tabs.attachments" [header]="t('attachments.title')" [cache]="false">
            <ng-template pTemplate="content">
              <app-candidate-files
                [model]="candidate"
                [candidateForm]="form"
                [readonly]="!isCreator && !isEditable"
                [selectedSharing]="sharing"
                [showInternalDoc]="canSeeInternalDocuments"
                [candidateImmigrationCountry]="candidate.immigrationCountry"
                [candidateFunction]="candidate.function"
                [showCompletionStatus]="canSeeCompletionAtDocuments"
                [candidateSourceSharedRequiredDocuments]="tabs.requiredDocument"
              ></app-candidate-files>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel
            *ngIf="tabs.requiredDocument || isLicensed"
            [header]="t('requiredDocument.overview.title')"
            [cache]="false"
          >
            <ng-template pTemplate="content">
              <app-document-overview
                [candidateId]="{ candidateId: candidate.id, organizationId: candidate.organizationId }"
                [organizationId]="currentCollectionService?.sharingOrganizationId"
                [candidateImmigrationCountry]="candidate.immigrationCountry"
                [candidateFunction]="candidate.function"
                [tabIsShared]="tabs.requiredDocument"
              ></app-document-overview>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel *ngIf="isLicensed" [header]="t('templates.title')" [cache]="false">
            <ng-template pTemplate="content">
              <app-candidate-document-templates
                [model]="candidate"
                [readonly]="isReadOnlyCollaboration"
                [collectionId]="selectedCollectionId"
                [collectionOrganizationId]="selectedCollectionOrganizationId"
              ></app-candidate-document-templates>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel
            *ngIf="isLicensed && tabs.onlineApplications"
            [header]="t('onlineApplication.title')"
            [cache]="false"
          >
            <ng-template pTemplate="content">
              <app-candidate-online-application
                [model]="candidate"
                [collectionId]="selectedCollectionId"
                [collectionOrganizationId]="selectedCollectionOrganizationId"
              >
              </app-candidate-online-application>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel *ngIf="tabs.cardView" [header]="t('candidate.cardView')" [cache]="false">
            <ng-template pTemplate="content">
              <app-candidate-card
                [candidateData]="candidate"
                [sharing]="sharing"
                [collectionId]="selectedCollectionId"
                [collectionOrganizationId]="selectedCollectionOrganizationId"
                (filePreview)="openFilePreview($event)"
              ></app-candidate-card>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel *ngIf="tabs.governance" [header]="t('candidate.onboarding')" [cache]="false">
            <app-candidate-governance
              [form]="form.controls.governance"
              [candidate]="candidate"
              [readonly]="!isEditing || isReadOnlyCollaboration"
              [candidateId]="candidate.id"
              [candidateOrganizationId]="candidate.organizationId"
              [systemOnboarding]="candidate.systemOnboarding"
              [lastLoginDate]="candidate.lastLoginDate"
              [messagesReception]="messagesReception"
              [buttonsDisabled]="isBusy"
              [isMultiEditMode]="isMultiEditMode"
              (multiInvite)="multiInvite.emit()"
              (multiUninvite)="multiUninvite.emit()"
              (invited)="reloadEmails()"
              (toggled)="toggleMessagesReception()"
            ></app-candidate-governance>
          </p-tabPanel>

          <p-tabPanel
            *ngIf="tabs.emails"
            [header]="t('emails.title')"
            [headerStyleClass]="hasEmailErrors ? 'p-error' : ''"
            [cache]="false"
          >
            <ng-template pTemplate="content">
              <app-emails
                [organizationId]="candidate.organizationId"
                [candidateId]="candidate.id"
                (hasNoErrors)="updateEmailErrors(!$event)"
              ></app-emails>
            </ng-template>
          </p-tabPanel>
        </p-tabView>
      </form>

      <div *ngIf="isMasterUser && !isNil(form)" [style.background-color]="'#eee'" [style.padding]="'0.2rem 1rem'">
        <pre *ngIf="showFormErrors">{{ form | formErrors | json }}</pre>
        <pre *ngIf="showDirtyControls">{{ form | dirtyControls | json }}</pre>
      </div>
    </p-card>
  </app-document-dropzone>

  <p-sidebar [visible]="!!filePreview" styleClass="p-sidebar-lg">
    <app-document-preview-dialog
      *ngIf="filePreview"
      [subheader]="
        filePreview.fileType
          | staticData
            : StaticDataType.AllowedUploadFileTypes
            : null
            : { candidateId: candidate.id, organizationId: candidate.organizationId }
          | async
      "
      [fileName]="filePreview.fileName"
      [url]="filePreview.url"
      (closed)="closeFilePreview()"
    ></app-document-preview-dialog>
  </p-sidebar>

  <app-candidate-document-edit-dialog></app-candidate-document-edit-dialog>
</ng-container>
