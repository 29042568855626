import { Injectable } from "@angular/core";
import { Candidate } from "@ankaadia/graphql";
import { isNil, translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng-v17/api";
import { BehaviorSubject, Observable, of, pairwise, switchMap, take } from "rxjs";
import { UpdatedConfirmation } from "../../../shared/primeng/confirm-popup-fix/confirm-popup-fix.directive";
import { valuesOf } from "../../../shared/services/form.helper";
import { CandidateForm } from "../../candidate-form/candidate-form.model";

/**
 * Intercepts profession changes on the candidate form and displays a confirmation popup.
 * If the operation is confirmed, carries on; otherwise resets the profession.
 *
 * Synchronization of competencies is done in the formly definition. Would have done it here but screw formly, seriously.
 *
 * Keeping the form component smaller, y'know.
 */
@Injectable({ providedIn: "root" })
export class CandidateFormProfessionChangeService {
  constructor(private readonly confirmationService: ConfirmationService) {}

  setup(component: CandidateFormProfessionChangeComponent): BehaviorSubject<string> {
    const subject = new BehaviorSubject<string>(null);
    valuesOf(component.form.controls.profession)
      .pipe(
        pairwise(),
        switchMap(([previous, current]) => {
          // double equal sign so that it treats `null` and `undefined` as equivalent values
          const confirmationRequired =
            !isNil(previous) && previous != current && !component.isMultiEditMode && component.isEditing;
          return confirmationRequired ? this.confirm(current, component) : of(current);
        })
      )
      .subscribe(subject);
    return subject;
  }

  private confirm(profession: string, component: CandidateFormProfessionChangeComponent): Observable<string> {
    return new Observable<string>((observer) => {
      const accept = (): void => {
        component.candidate.profession = profession;
        observer.next(profession);
        observer.complete();
      };

      const reject = (): void => {
        component.form.controls.profession.setValue(component.candidate.profession);
        observer.next(component.candidate.profession);
        observer.complete();
      };

      this.confirmationService.confirm({
        target: component.professionElement,
        message: translate("candidate.professionWarning"),
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        accept: () => accept(),
        reject: () => reject(),
        close: () => reject(),
      } as UpdatedConfirmation);
    }).pipe(take(1));
  }
}

interface CandidateFormProfessionChangeComponent {
  candidate: Candidate;
  form: CandidateForm;
  isMultiEditMode: boolean;
  professionElement: HTMLElement;
  isEditing: boolean;
}
