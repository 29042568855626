import { Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UserPermission } from "@ankaadia/ankaadia-shared";
import {
  EducationCourseForCandidateListFragment,
  EducationCourseForCandidateListToAddFragment,
  EducationCourseForViewFragment,
  EducationExamResultFullDataFragment,
  EducationModuleForSelectionFragment,
  OrganizationForSelectionFragment,
} from "@ankaadia/graphql";
import { FetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { PrimeTemplate } from "primeng-v17/api";
import { BlockUIModule } from "primeng-v17/blockui";
import { TabViewModule } from "primeng-v17/tabview";
import { combineLatest, debounceTime, Observable, Subject } from "rxjs";
import { HasChanges } from "../../../shared/guards/confirm-deactivate.guard";
import { TabViewRememberTabDirective } from "../../../shared/primeng/tab-view-remember-tab/tab-view-remember-tab.directive";
import { SettingsService } from "../../../shared/services/settings.service";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { EducationCourseService } from "../../education/education-courses/education-course.service";
import { EducationExamResultService } from "../../education/education-exam-results/education-exam-results.service";
import { ExamFragment } from "../../education/education-exams/education-exam-table/education-exam-table.component";
import { EducationExamService } from "../../education/education-exams/education-exam.service";
import { EducationModuleService } from "../../education/education-modules/education-module.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import { CandidateEducationCoursePerformanceComponent } from "../candidate-education-course-performance/candidate-education-course-performance.component";
import { CandidateEducationExamResultsComponent } from "../candidate-education-exam-results/candidate-education-exam-results.component";
import {
  CandidateEducationExamsComponent,
  CandidateExamFragment,
} from "../candidate-education-exams/candidate-education-exams.component";

@Component({
  selector: "app-candidate-education",
  templateUrl: "./candidate-education.component.html",
  styleUrl: "./candidate-education.component.scss",
  imports: [
    TranslateDirective,
    BlockUIModule,
    TabViewModule,
    TabViewRememberTabDirective,
    CandidateEducationCoursePerformanceComponent,
    PrimeTemplate,
    CandidateEducationExamsComponent,
    CandidateEducationExamResultsComponent,
  ],
})
export class CandidateEducationComponent implements OnInit, OnChanges, HasChanges {
  @Input({ required: true })
  candidateId: {
    candidateId: string;
    candidateOrganizationId: string;
  };

  protected readonly hasEducationFullAccess = this.settingsService.hasAnyPermission([
    UserPermission.Administrator,
    UserPermission.CourseAdministrator,
  ]);

  protected readonly userOrganizationId = this.settingsService.organizationId;

  @Input({ required: true })
  readonly: boolean;

  private readonly loadingTrigger$ = new Subject<void>();

  @ViewChild(CandidateEducationExamResultsComponent)
  private readonly examResultsTable: CandidateEducationExamResultsComponent;

  protected modules: EducationModuleForSelectionFragment[] = [];
  educationProviders: OrganizationForSelectionFragment[] = [];
  protected exams: CandidateExamFragment[] = [];
  protected examResults: EducationExamResultFullDataFragment[] = [];
  protected courses: EducationCourseForViewFragment[] = [];

  constructor(
    private readonly settingsService: SettingsService,
    private readonly moduleService: EducationModuleService,
    private readonly courseService: EducationCourseService,
    private readonly educationExamService: EducationExamService,
    private readonly examResultService: EducationExamResultService,
    private readonly organizationService: OrganizationsService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.loadingTrigger$
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(50))
      .subscribe(() => this.loadEducationData());

    this.loadingTrigger$.next();
  }

  hasChanges(): boolean {
    return this.examResultsTable?.hasChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Data is also reloaded when readonly changes to also refresh the data after saving/canceling
    // This should not have a performance impact as the queries are cached
    if (changes.candidateId || changes.readonly) this.loadingTrigger$.next();
  }

  saveEducationData(): void {
    this.examResultsTable.saveAll().subscribe(() => this.loadingTrigger$.next());
  }

  onExamRemoved(exam: ExamFragment): void {
    this.exams = this.exams.filter((x) => x.id !== exam.id);
  }

  onExamAdded(exam: CandidateExamFragment): void {
    this.exams = [...this.exams, exam];
  }

  onExamUpdated(exam: ExamFragment): void {
    const examFound = this.exams.find((x) => x.id === exam.id);
    this.exams = [...this.exams.filter((x) => x.id !== exam.id), { ...exam, participantId: examFound?.participantId }];
  }

  onAddedToCourse(_course: EducationCourseForCandidateListToAddFragment): void {
    this.getCourses$("network-only")
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((courses) => (this.courses = courses));
  }

  private loadEducationData(): void {
    if (!this.candidateId?.candidateId) return;

    const modules$ = this.moduleService.listSelectable(this.userOrganizationId);

    const educationProviders$ = this.organizationService.getLinkedOrganizations(this.userOrganizationId, true);

    const examResults$ = this.examResultService.getByCandidateId({
      candidateId: this.candidateId.candidateId,
      candidateOrganizationId: this.candidateId.candidateOrganizationId,
    });

    const exams$ = this.educationExamService.getByCandidateId(
      this.candidateId.candidateId,
      this.candidateId.candidateOrganizationId
    );

    const adHoxExams$ = this.educationExamService.getAdHocByCandidateId(
      this.candidateId.candidateId,
      this.candidateId.candidateOrganizationId
    );

    const courses$ = this.getCourses$("cache-first");

    combineLatest([modules$, educationProviders$, courses$, exams$, adHoxExams$, examResults$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([modules, educationProviders, courses, exams, adHoxExams, examResults]) => {
        this.modules = modules;
        this.educationProviders = educationProviders;
        this.courses = courses;
        this.exams = [...exams, ...adHoxExams].map((x) => ({ ...x.exam, participantId: x.participant?.id }));
        this.examResults = examResults;
      });
  }

  private getCourses$(fetchPolicy: FetchPolicy): Observable<EducationCourseForCandidateListFragment[]> {
    return this.courseService.listForCandidate(
      this.candidateId.candidateId,
      this.candidateId.candidateOrganizationId,
      fetchPolicy
    );
  }
}
