import { Component, OnInit } from "@angular/core";
import { FieldActivityDiffFragment } from "@ankaadia/graphql";
import { cloneDeep } from "lodash";
import { PrimeTemplate } from "primeng-v17/api";
import { Button } from "primeng-v17/button";
import { CardModule } from "primeng-v17/card";
import { SidebarModule } from "primeng-v17/sidebar";
import { EscapeButtonDirective } from "../../../shared/directives/escape-button.directive";
import { SidebarAdditionalComponentDirective } from "../../../shared/primeng/sidebar-additional-component/sidebar-additional-component.directive";
import { SidebarOptionsDirective } from "../../../shared/primeng/sidebar-options/sidebar-options.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { FieldDiffComponent } from "../../activity/field-diff/field-diff.component";
import { CandidateFieldDiffService } from "./candidate-field-diff.service";

@Component({
  selector: "app-candidate-field-diff",
  templateUrl: "./candidate-field-diff.component.html",
  imports: [
    TranslateDirective,
    SidebarModule,
    SidebarAdditionalComponentDirective,
    SidebarOptionsDirective,
    CardModule,
    FieldDiffComponent,
    PrimeTemplate,
    Button,
    EscapeButtonDirective,
  ],
})
export class CandidateFieldDiffComponent implements OnInit {
  showSidebar: boolean;
  diff: FieldActivityDiffFragment[] = null;

  constructor(private readonly service: CandidateFieldDiffService) {}

  ngOnInit(): void {
    this.service.fieldDiff.subscribe((diff) => {
      this.service.suspend();
      this.diff = cloneDeep(diff);
      this.showSidebar = true;
    });
  }

  close(): void {
    this.showSidebar = false;
    this.diff = null;
    this.service.resume();
  }
}
