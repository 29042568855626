<ng-container *appTranslate="let t">
  <app-process-tasks
    *ngIf="process$ | async as process"
    [process]="process"
    [processes]="processes"
    [candidate]="candidate$ | async"
    [tasks]="shownTasks$ | async"
    [collections]="collections"
    mode="Candidate"
    [processLanguage]="language"
    [allowedInstanceStates]="allowedTaskStates"
    [instanceStates]="defaultTaskStatus"
    [buttonsDisabled]="buttonsDisabled || !readonly"
    (filterUpdated)="selectedTaskStatus$.next($event)"
    (acted)="taskActed.emit()"
    (configured)="taskConfigured.emit()"
    (deleted)="taskDeleted.emit()"
  >
    <ng-template #filter>
      <p-multiSelect
        styleClass="mt-2 md:mt-0 md:ml-2 mb-2 md:mb-0"
        [formControl]="selectedProcessesControl"
        [options]="processes"
        filterBy="displayName"
        optionLabel="displayName"
        dataKey="id"
        [showClear]="true"
        [placeholder]="t('process.placeholder')"
      ></p-multiSelect>
    </ng-template>
  </app-process-tasks>
</ng-container>
