<ng-container *appTranslate="let t">
  <p-toolbar>
    <div class="field">
      <label [for]="selectedProcess">{{ t("process.title") }}</label>
      <p-dropdown
        #selectedProcess
        [formControl]="selectedProcessControl"
        [options]="processes"
        [placeholder]="t('process.placeholder')"
        optionLabel="displayName"
        styleClass="mw-500 mr-2"
      ></p-dropdown>
    </div>
  </p-toolbar>

  <app-process-progress
    [progress]="progress$ | async"
    [processRoles]="selectedProcessRoles$ | async"
    [processLanguage]="language"
    (searchEvent)="onSearch($event)"
  />
</ng-container>
