import { Component, input, model } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CandidateDataSource } from "@ankaadia/ankaadia-shared";
import { TranslocoService } from "@jsverse/transloco";
import { DropdownModule } from "primeng-v17/dropdown";
import { TooltipModule } from "primeng-v17/tooltip";
import { AppendToBodyDirective } from "../../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";

@Component({
  selector: "app-candidate-license-owner-specific-data",
  templateUrl: "./candidate-license-owner-specific-data.component.html",
  styleUrl: "./candidate-license-owner-specific-data.component.scss",
  imports: [
    TranslateDirective,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormsModule,
    TooltipModule,
  ],
})
export class CandidateLicenseOwnerSpecificDataComponent {
  isOriginalTabShared = input.required<boolean>();
  isLicenseOwnerSpecificDataEnabled = input.required<boolean>();
  dataSource = model<CandidateDataSource>("owner");

  candidateDataSourceOptions: candidateDataSourceoption[] = [
    { value: "owner", label: this.transloco.translate("candidate.dataSource.owner") },
    { value: "userOrg", label: this.transloco.translate("candidate.dataSource.userOrg") },
  ];

  constructor(private readonly transloco: TranslocoService) {}
}

interface candidateDataSourceoption {
  label: string;
  value: CandidateDataSource;
}
