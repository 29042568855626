import { Component, EventEmitter, Output } from "@angular/core";
import { InViewTriggerOnInitComponent } from "./in-view-trigger-on-init/in-view-trigger-on-init.component";

@Component({
  selector: "app-in-view-trigger",
  templateUrl: "./in-view-trigger.component.html",
  imports: [InViewTriggerOnInitComponent],
})
export class InViewTriggerComponent {
  @Output() readonly inView = new EventEmitter<void>();
}
