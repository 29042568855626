import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Candidate, Document, Sharing } from "@ankaadia/graphql";
import { DefaultCardViewComponent } from "../../../organization-specific/default/default-candidate-card/default-card-view-component.component";
import { CompleteCandidateForView, FilePreview } from "../candidate-card-component.model";
import { CandidatesService } from "../candidates.service";

@Component({
  selector: "app-candidate-card",
  templateUrl: "./candidate-card.component.html",
  imports: [DefaultCardViewComponent],
})
export class CandidateCardComponent implements OnInit {
  private _sharing: Sharing;
  @Input() set sharing(val: Sharing) {
    this._sharing = val;
  }

  get sharing(): Sharing {
    return this._sharing;
  }

  @Input()
  collectionId: string;

  @Input()
  collectionOrganizationId: string;

  private _candidateData: CompleteCandidateForView;
  private _candidate: Candidate;

  @Input() set candidateData(val: CompleteCandidateForView | Candidate) {
    if (isCandidateForView(val)) {
      this._candidateData = val;
    } else {
      this._candidate = val;
    }
  }

  get candidateData(): CompleteCandidateForView {
    return this._candidateData;
  }

  private _candidateFiles: Document[];
  @Input()
  set candidateFiles(files: Document[]) {
    this._candidateFiles = files;
  }

  get candidateFiles(): Document[] {
    return this._candidateFiles;
  }

  private _candidateTag: string;
  @Input()
  set candidateTag(tag: string) {
    this._candidateTag = tag;
  }

  get candidateTag(): string {
    return this._candidateTag;
  }

  private _isPresentation;
  @Input()
  set isPresentation(value: boolean) {
    this._isPresentation = value;
  }

  get isPresentation(): boolean {
    return this._isPresentation;
  }

  @Output()
  readonly filePreview = new EventEmitter<FilePreview>();

  constructor(private readonly candidatesService: CandidatesService) {}

  ngOnInit(): void {
    if (this._candidate?.id != null) {
      this.candidatesService
        .getCandidateForView({
          id: this._candidate.id,
          orgId: this._candidate.organizationId,
          originalColId: this._sharing?.sharedCollectionId,
          originalOrgId: this._sharing?.organizationId,
        })
        .subscribe((candidate) => {
          this.candidateData = candidate;
        });
    }
  }
}

function isCandidateForView(candidate: CompleteCandidateForView | Candidate): candidate is CompleteCandidateForView {
  return (candidate as CompleteCandidateForView).__typename === "CandidateForView";
}
